import { CustomerPaymentMethodsDocument } from "@/generated/requests/pos";
import { UserAddress } from "@/generated/requests/services";
import { useRouter } from "next/router";
import { useState } from "react";
import {
  DeleteAddressDocument,
  UpdateUserMetaDocument,
  UpsertAddressDocument,
} from "../../../generated/requests/backend";
import { EditMyselfDocument, FetchCustomerInfoDocument } from "../../../generated/requests/services";
import { useBackendMutation, useCustomerMutation, useCustomerQuery, usePOSQuery } from "../../../lib/apollo-hooks";

export default function useCustomerAccount() {
  const { locale } = useRouter();
  const l = locale.toUpperCase().split("-")[1];
  const {
    data,
    loading: loadingCustomer,
    refetch: refetchInfo,
  } = useCustomerQuery(FetchCustomerInfoDocument, { variables: { params: { cursor: "" } } });
  const {
    data: paymentMethods,
    loading: loadingPaymentMethods,
    refetch: refetchPaymentMethods,
  } = usePOSQuery(CustomerPaymentMethodsDocument, {
    variables: { stripeRegion: l },
    skip: !l || !data?.me?.userId,
  });
  const [EditMyself] = useCustomerMutation(EditMyselfDocument);
  const [deleteAddress] = useBackendMutation(DeleteAddressDocument);
  const [upsertAddress] = useBackendMutation(UpsertAddressDocument);
  const [updateDefaultAddress] = useBackendMutation(UpdateUserMetaDocument);
  const [updatingAddress, setUpdatingAddress] = useState(false);
  const [saving, setSaving] = useState(false);

  const saveMyself = async (input) => {
    setSaving(true);
    await EditMyself({ variables: { input: { ...input } } });
    refetchInfo();
    setSaving(false);
  };

  const deleteUserAddress = async (addressId: string) => {
    setUpdatingAddress(true);
    await deleteAddress({ variables: { addressId } });
    setUpdatingAddress(false);
    refetchInfo();
  };

  const saveUserAddress = async (address: UserAddress, saveAsDefaultAddress = false) => {
    setUpdatingAddress(true);
    const savedUserAddress = await upsertAddress({ variables: { upsert: address, returnValidation: false } });
    if (savedUserAddress?.data?.privateMyself?.upsertAddress?.addressId && saveAsDefaultAddress) {
      await updateDefaultAddress({
        variables: { input: { defaultAddressId: savedUserAddress.data.privateMyself.upsertAddress.addressId } },
      });
    }
    setUpdatingAddress(false);
    refetchInfo();
  };

  const saveDefaultAddress = async (addressId) => {
    setUpdatingAddress(true);
    await updateDefaultAddress({ variables: { input: { defaultAddressId: addressId } } });
    setUpdatingAddress(false);
    refetchInfo();
  };

  return {
    customerInfo: data?.me,
    loadingCustomer,
    saveMyself,
    saving,
    paymentMethods: paymentMethods?.customer?.customer?.cards,
    loadingPaymentMethods,
    refetchPaymentMethods,
    deleteUserAddress,
    saveUserAddress,
    updatingAddress,
    saveDefaultAddress,
  };
}
