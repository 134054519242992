import i401k from "@/images/401k-icon.png";
import blackFridayWebBanner from "@/images/Black Friday Promotion_Website_Bannerpng.png";
import CACookies from "@/images/CA-cookies.png";
import CAFlag from "@/images/CA-flag.svg";
import CAOwners from "@/images/CAOwners.png";
import cchand from "@/images/CChand.png";
import canadaMilkCC from "@/images/Canada_MilkCC.png";
import chocolateChipTallStack from "@/images/ChocolateChip_TallStack_Transparent@2x.png";
import cinnamonSquareWebBanner from "@/images/Cinnamon-Squares-APR_WebBanner.png";
import CookiesLargerThanLife from "@/images/CookiesLargerThanLife.png";
import CookiesLargerThanLife_large from "@/images/CookiesLargerThanLife large.png";
import crumblFounders from "@/images/Crumbl Founders.jpg";
import CrumblOntarioOwners from "@/images/CrumblOntarioOwners.png";
import crumblCertification from "@/images/Crumbl_2023_Certification_Badge.png";
import cyberMondayWebBanner from "@/images/Cyber-Monday-Website-Banner.png";
import facebookReviewLogo from "@/images/Facebook Review Logo.svg";
import firstStore from "@/images/FirstStore@2x.png";
import flagHand from "@/images/Flaghand.png";
import FreeCookieDay2 from "@/images/FreeCookieDay2.png";
import FreeCookieDay2_large from "@/images/FreeCookieDay2_large.png";
import FreeCookies from "@/images/FreeCookies.png";
import FreeCookies_large from "@/images/FreeCookies large.png";
import bannerFreeDelivery from "@/images/Free Delivery.png";
import googleReviewLogo from "@/images/Google Review Logo.svg";
import keyLimePieWebBanner from "@/images/KeyLimePie_WebBanner.png";
import loyaltyImage from "@/images/Loyalty_Image.png";
import minisWeekWebBanner from "@/images/Minis-Week_WebBanner.png";
import PinkParty from "@/images/PinkParty.png";
import PinkParty_large from "@/images/PinkParty large.png";
import Rectangle4880 from "@/images/Rectangle 4880.png";
import Rectangle4882 from "@/images/Rectangle 4882.png";
import Rectangle4883 from "@/images/Rectangle 4883.png";
import Rectangle4884 from "@/images/Rectangle 4884.png";
import Rectangle4885 from "@/images/Rectangle 4885.png";
import Rectangle4886 from "@/images/Rectangle 4886.png";
import Rectangle4887 from "@/images/Rectangle 4887.png";
import stPatricksDayWebBanner from "@/images/StPatricksDay_WebBanner.png";
import storeHero from "@/images/Store Page Hero Image.png";
import largeStoreHero from "@/images/Store Page Hero Image Large.png";
import superBowlWebBanner from "@/images/Superbowl-Web-Banner.png";
import tresLechesCake from "@/images/TresLechesCake_Web_Banner.png";
import UKFlag from "@/images/UK-flag.svg";
import USFlag from "@/images/US-flag.png";
import yelpReviewLogo from "@/images/Yelp Review Logo.svg";
import abcNewsLogo from "@/images/abc news.svg";
import addButton from "@/images/add-button.svg";
import addFunds from "@/images/add-funds.svg";
import addRecipient from "@/images/add recipient icon.svg";
import alarmClockBlack from "@/images/alarm-clock-black.png";
import alarmClock from "@/images/alarm-clock.png";
import amex from "@/images/amex.png";
import android from "@/images/android-icon.svg";
import androidWhite from "@/images/android-white.svg";
import angleDown from "@/images/angle-down.svg";
import angleRightSmall from "@/images/angle-right-small.svg";
import appScreenshot from "@/images/app-screenshot@2x.png";
import appArrow from "@/images/app_arrow.png";
import taskApp from "@/images/app icon.svg";
import apple from "@/images/apple-icon.svg";
import appleWhite from "@/images/apple-white.svg";
import arrowDown from "@/images/arrow-down.svg";
import arrowExport from "@/images/arrow-export.png";
import arrowLeft from "@/images/arrow-left.svg";
import arrowRightSmall from "@/images/arrow-right-small.png";
import arrowRight from "@/images/arrow-right.svg";
import arrowUp from "@/images/arrow-up.svg";
import authenticity1 from "@/images/authenticity1.png";
import authenticity2 from "@/images/authenticity2.png";
import authenticity3 from "@/images/authenticity3.png";
import authenticity4 from "@/images/authenticity4.png";
import authenticity5 from "@/images/authenticity5.png";
import taskAddBirthday from "@/images/balloons icon.svg";
import bannerHolidayCatering from "@/images/banner-holiday-catering.png";
import bannerWonka from "@/images/banner-wonka.png";
import bayamonOwners from "@/images/bayamon-owners.png";
import birthdayBalloons from "@/images/birthday-balloons.svg";
import birthdayIllustration from "@/images/birthday-illustration.svg";
import blackPlus from "@/images/black-add-icon.svg";
import blackArrowDown from "@/images/black-arrow-down.svg";
import blackStar from "@/images/black-star-icon.svg";
import boxing from "@/images/boxing-icon.svg";
import briefCase from "@/images/briefcase.png";
import bringingPeopleTogether from "@/images/bringing-people-together-box.png";
import CALine1 from "@/images/ca-brokenline-1.png";
import CALine2 from "@/images/ca-brokenline-2.png";
import CALine3 from "@/images/ca-brokenline-3.png";
import CAHomepageCookies from "@/images/ca-homepage-cookies.png";
import CASS1 from "@/images/ca-ss-1.png";
import CASS2 from "@/images/ca-ss-2.png";
import CASS3 from "@/images/ca-ss-3.png";
import CASS4 from "@/images/ca-ss-4.png";
import pinkCalendar from "@/images/calendar-icon-pink.svg";
import calendar from "@/images/calendar-icon.svg";
import calendarStar from "@/images/calendar-star.png";
import carFilledWhite from "@/images/car-filled-white.svg";
import carFilled from "@/images/car-filled.png";
import carWhite from "@/images/car-white.png";
import car from "@/images/car.png";
import careersHero from "@/images/careers-hero.png";
import careersSeparator from "@/images/careers-separator.png";
import pinkCash from "@/images/cash-icon-pink.svg";
import cashIconWhite from "@/images/cash-icon-white.svg";
import catering1 from "@/images/catering-1.png";
import catering2 from "@/images/catering-2.png";
import catering3 from "@/images/catering-3.png";
import catering4 from "@/images/catering-4.png";
import catering5 from "@/images/catering-5.png";
import catering6 from "@/images/catering-6.png";
import catering7 from "@/images/catering-7.png";
import catering8 from "@/images/catering-8.png";
import catering9 from "@/images/catering-9.png";
import cateringBox from "@/images/catering-box.png";
import cateringHeroSm from "@/images/catering-hero-sm.png";
import cateringHero from "@/images/catering-hero.png";
import pinkCatering from "@/images/catering-icon-pink.svg";
import cateringLine from "@/images/catering-line.png";
import cateringLineup from "@/images/catering-lineup.png";
import cateringScreenshot from "@/images/catering-screenshot@2x.png";
import cateringWhenWhere from "@/images/catering-when-where.png";
import checkCircle from "@/images/check-circle.png";
import checkWhite from "@/images/check-white.svg";
import checkMark from "@/images/check.svg";
import checkboxActive from "@/images/checkbox-active.svg";
import checkMarkHuge from "@/images/checkmark-circle-icon.svg";
import checkMarkIcon from "@/images/checkmark.svg";
import chefHat from "@/images/chef-hat.svg";
import childcareReimbursement from "@/images/childcare_reimbursement-icon.png";
import cities from "@/images/cities-icon.svg";
import clipboard from "@/images/clipboard-list-check.png";
import clipboardText from "@/images/clipboard-text.png";
import clock from "@/images/clock.png";
import closeButton from "@/images/close-button.svg";
import closeWhite from "@/images/close-icon-white.svg";
import close from "@/images/close-icon.svg";
import closedSign from "@/images/closed-sign.svg";
import cnbcLogo from "@/images/cnbc logo@2x.png";
import collabLarge from "@/images/collab-lg.png";
import collab from "@/images/collab.png";
import completePurchase from "@/images/complete-purchase-icon.svg";
import checkmarkCompleted from "@/images/completed-checkmark.svg";
import connectedArrow from "@/images/connected-arrow.svg";
import construction from "@/images/construction-icon.svg";
import contactDetails from "@/images/contact-details.svg";
import contactList from "@/images/contact list icon.svg";
import cookieCompositionMobile from "@/images/cookie-composition-mobile.png";
import cookieComposition from "@/images/cookie-composition-web.png";
import cookieHand from "@/images/cookie-hand-icon.svg";
import pinkCookie from "@/images/cookie-icon-pink.svg";
import cookie from "@/images/cookie-icon.svg";
import CookieMapArrow from "@/images/cookie-map-arrow.png";
import miniCookie from "@/images/cookie-mini@2x.png";
import cookieOutlineIcon from "@/images/cookie-outline-icon.png";
import regularCookie from "@/images/cookie-regular@2x.png";
import cookieSingleIcon from "@/images/cookie-single-icon.svg";
import cookieStackIcon from "@/images/cookie-stack-icon.svg";
import cookieChoice from "@/images/cookie choice icon.svg";
import freeCrumbCookie from "@/images/cookie icon.svg";
import copy from "@/images/copy-alt.png";
import coverYourCookiesSm from "@/images/cover-your-cookies-sm.png";
import creamBg from "@/images/cream-bg.png";
import createYourOwnBundle from "@/images/create-your-own-bundle.png";
import createGift from "@/images/create gift icon.svg";
import creditCard from "@/images/credit-card.png";
import crewImage1 from "@/images/crew1@2x.png";
import crewImage2 from "@/images/crew2@2x.png";
import crewImage3 from "@/images/crew3@2x.png";
import crewImage4 from "@/images/crew4@2x.png";
import crewImage5 from "@/images/crew5@2x.png";
import crewImage6 from "@/images/crew6@2x.png";
import crewImage7 from "@/images/crew7@2x.png";
import crewImage8 from "@/images/crew8@2x.png";
import crewImage9 from "@/images/crew9@2x.png";
import crewImage from "@/images/crew@2x.png";
import crumb from "@/images/crumb.svg";
import qrCode from "@/images/crumbl-app-qr-code.svg";
import crumblCardSampleUsage from "@/images/crumbl-card-sample-usage.svg";
import crumblCard from "@/images/crumbl-card.svg";
import crumblCashCelebrate from "@/images/crumbl-cash-celebrate-icon.svg";
import crumblCash from "@/images/crumbl-cash-icon.svg";
import fullLogo from "@/images/crumbl-cookies-logo-full.svg";
import crumblLogo from "@/images/crumbl-cookies-logo.svg";
import joinCrew from "@/images/crumbl-crew.png";
import crumbsCelebrate from "@/images/crumbl-crumbs-celebrate-icon.svg";
import logo from "@/images/crumbl-logo.png";
import crumblStory from "@/images/crumbl-story.png";
import crumblCares from "@/images/crumbl_cares-icon.png";
import crumblCookiesLogo from "@/images/crumbl_logo.svg";
import crumblLogoLight from "@/images/crumbl_logo_white.svg";
import crumbsToCash from "@/images/crumbs-to-cash.svg";
import csvDownload from "@/images/csv download icon.svg";
import csvUpload from "@/images/csv upload icon.svg";
import csvUploadSmall from "@/images/csv upload icon sm.svg";
import curbsidePinkIcon from "@/images/curbside-pickup-icon-pink.svg";
import customerSupportMobile from "@/images/customer-support-image-mobile.png";
import date from "@/images/date-icon.svg";
import deleteIcon from "@/images/delete-icon.svg";
import deleteAddress from "@/images/delete address icon.svg";
import { default as deliveryPinkIcon, default as pinkDelivery } from "@/images/delivery-icon-pink.svg";
import deliveryVanDark from "@/images/delivery-van-icon-black.svg";
import deliveryVan from "@/images/delivery-van-icon.svg";
import desktop from "@/images/desktop icon.svg";
import detailsImage from "@/images/detailsImage.png";
import digitalGiftCard from "@/images/digital-gift-cards.png";
import discountsCrumblcash from "@/images/discounts_crumblcash-icon.png";
import discover from "@/images/discover.png";
import downloadArrow from "@/images/download-arrow.svg";
import downloadApp from "@/images/download app illustration.svg";
import dressing from "@/images/dressing-icon.svg";
import dropletOutline from "@/images/droplet-outline.png";
import taskFacebook from "@/images/earn facebook icon.svg";
import earnFacebook from "@/images/earn facebook illustration.svg";
import taskInstagram from "@/images/earn insta icon.svg";
import earnInstagram from "@/images/earn insta illustration.svg";
import taskLinkedIn from "@/images/earn linkedin icon.svg";
import earnLinkedIn from "@/images/earn linkedin illustration.svg";
import taskPinterest from "@/images/earn pinterest icon.svg";
import earnPinterest from "@/images/earn pinterest illustration.svg";
import taskTiktok from "@/images/earn tiktok icon.svg";
import earnTiktok from "@/images/earn tiktok illustration.svg";
import taskTwitter from "@/images/earn twitter icon.svg";
import earnTwitter from "@/images/earn twitter illustration.svg";
import taskYoutube from "@/images/earn youtube icon.svg";
import earnYoutube from "@/images/earn youtube illustration.svg";
import editCircle from "@/images/edit-circle.png";
import edit from "@/images/edit-icon.svg";
import eggImage from "@/images/egg.svg";
import egg from "@/images/egg icon.svg";
import emailSignup from "@/images/email-signup.svg";
import emailCookies from "@/images/email cookies large.png";
import emailCookiesSmall from "@/images/email cookies small.png";
import taskEmailSignup from "@/images/email icon.svg";
import enjoyCookies from "@/images/enjoy-cookies.svg";
import exclamationCircle from "@/images/exclamation-circle.png";
import experiences1 from "@/images/experiences1.png";
import experiences2 from "@/images/experiences2.png";
import experiences3 from "@/images/experiences3.png";
import experiences4 from "@/images/experiences4.png";
import experiences5 from "@/images/experiences5.png";
import facebookButton from "@/images/facebook-button.png";
import socialFacebook from "@/images/facebook-icon.svg";
import facebookLogo from "@/images/facebook.svg";
import famousPinkBoxClipped from "@/images/famous-pink-box-clipped.png";
import famousPinkBox from "@/images/famous-pink-box@2x.png";
import fan1 from "@/images/fan-1.png";
import fan2 from "@/images/fan-2.png";
import fan3 from "@/images/fan-3.png";
import fan4 from "@/images/fan-4.png";
import fan5 from "@/images/fan-5.png";
import fan6 from "@/images/fan-6.png";
import fan7 from "@/images/fan-7.png";
import fan8 from "@/images/fan-8.png";
import fan9 from "@/images/fan-9.png";
import fan10 from "@/images/fan-10.png";
import fan11 from "@/images/fan-11.png";
import filledSparkle from "@/images/filled-sparkle.png";
import filledTruckBlack from "@/images/filled-truck-black.png";
import filledTruck from "@/images/filledTruck.png";
import foodTrayFilledWhite from "@/images/food-tray-filled-white.svg";
import foodTrayFilled from "@/images/food-tray-filled.svg";
import foodTrayWhite from "@/images/food-tray-white.svg";
import foodTray from "@/images/food-tray.svg";
import forbesLogo from "@/images/forbes logo@2x.png";
import freeCookieCardMockup from "@/images/free-cookie-card-mockup.png";
import fridge from "@/images/fridge.svg";
import fundraisersHeroImage from "@/images/fundraisers-hero-image.png";
import fundraisersIcon from "@/images/fundraisers-icon.png";
import fundraisingIcon from "@/images/fundraising icon@2x.png";
import gallery1 from "@/images/gallery1.png";
import gallery2 from "@/images/gallery2.png";
import gallery3 from "@/images/gallery3.png";
import gallery4 from "@/images/gallery4.png";
import gallery5 from "@/images/gallery5.png";
import gallery6 from "@/images/gallery6.png";
import gallery7 from "@/images/gallery7.png";
import giftActivity from "@/images/gift-activity.svg";
import giftcardGiftPinkIcon from "@/images/gift-card-icon-home-pink.svg";
import giftOutline from "@/images/gift-card-icon-outlined.svg";
import giftcardPinkIcon from "@/images/gift-card-icon-pink.svg";
import giftcardIcon from "@/images/gift-card-icon.svg";
import giftcards from "@/images/gift-cards.png";
import giftFilledWhite from "@/images/gift-filled-white.svg";
import giftFilled from "@/images/gift-filled.svg";
import giftWhite from "@/images/gift-white.svg";
import giftwrappedBox from "@/images/gift-wrapped-box.svg";
import gift from "@/images/gift.svg";
import giftcardFilledWhite from "@/images/giftcard-filled-white.svg";
import giftcardFilled from "@/images/giftcard-filled.svg";
import giftcardQRCode from "@/images/giftcard-qr-code.svg";
import giftcardWhite from "@/images/giftcard-white.svg";
import giftcard from "@/images/giftcard.svg";
import pinkGiftcard from "@/images/giftcards-icon-pink.svg";
import giftwrapSample from "@/images/giftwrap-sample.png";
import goodMorningAmericaLogo from "@/images/good morning america@2x.png";
import grandOpening from "@/images/grand-opening-icon.svg";
import greyPlus from "@/images/grey-add-icon.svg";
import greyCash from "@/images/grey-cash-icon.svg";
import cateringIcon from "@/images/grey-catering-icon.svg";
import greyCreditDebit from "@/images/grey-credit-debit-icon.svg";
import greyCrumblCash from "@/images/grey-crumblcash-icon.svg";
import curbsideIcon from "@/images/grey-curbside-icon.svg";
import deliveryIcon from "@/images/grey-delivery-icon.svg";
import greyEdit from "@/images/grey-edit-icon.svg";
import greyGiftcard from "@/images/grey-giftcard-icon.svg";
import greyInvoice from "@/images/grey-invoice-icon.svg";
import greyLocation from "@/images/grey-location-icon.svg";
import shippingIcon from "@/images/grey-shipping-icon.svg";
import hamburger from "@/images/hamburger icon.svg";
import handHoldingCookieFilledWhite from "@/images/hand-holding-cookie-filled-white.svg";
import handHoldingCookieRegular from "@/images/hand-holding-cookie-regular.png";
import handPieSilhouette from "@/images/hand-pie-silhouette.png";
import handPie from "@/images/hand-pie.png";
import handshake from "@/images/handshake@2x.png";
import hatChefSmall from "@/images/hat-chef-small.png";
import hatChef from "@/images/hat-chef.png";
import healthCoverage from "@/images/health_coverage-icon.png";
import homeWhite from "@/images/home-white.png";
import home from "@/images/home.png";
import homeSweetHome from "@/images/home sweet home.png";
import hours from "@/images/hours-icon.svg";
import appScreenshotSm from "@/images/iPhone14Mockup 3.png";
import phoneMockups from "@/images/iPhoneMockups_Combined.png";
import iphoneMockupsCombined from "@/images/iPhoneMockups_Combined.svg";
import icecreamScoop from "@/images/icecream-scoop-icon.svg";
import locationPin from "@/images/icon-crumbl-location-pin.svg";
import infoCircle from "@/images/info-circle.svg";
import info from "@/images/info-icon.svg";
import innovation1 from "@/images/innovation1.png";
import innovation2 from "@/images/innovation2.png";
import innovation3 from "@/images/innovation3.png";
import innovation4 from "@/images/innovation4.png";
import innovation5 from "@/images/innovation5.png";
import instagramButton from "@/images/instagram-button.png";
import instagram from "@/images/instagram-icon-sm.png";
import socialInstagram from "@/images/instagram-icon.svg";
import instagramLogo from "@/images/instagram.svg";
import interview from "@/images/interview-icon.svg";
import inventoryMinus from "@/images/inventory-minus.svg";
import ipadScreenshot from "@/images/ipad-screenshot@2x.png";
import iphoneOverlay from "@/images/iphone-overlay.png";
import iphoneSubscriptions from "@/images/iphone-subscriptions.png";
import landingPageHero from "@/images/landing-page-hero.png";
import landingPageHeroMobile from "@/images/landing-page-mobile.png";
import largerThanLifeMap from "@/images/larger-than-life-map.png";
import leaveReview from "@/images/leave-review.svg";
import taskLeaveReview from "@/images/leave review icon.svg";
import lineCatering from "@/images/line-catering.png";
import lineCurbside from "@/images/line-curbside.png";
import lineDelivery from "@/images/line-delivery.png";
import lineDriveThru from "@/images/line-drive-thru.png";
import linkedInLogo from "@/images/linkedin.png";
import listIcon from "@/images/list.png";
import location from "@/images/location-icn.svg";
import pinkLocationPin from "@/images/location-icon-pink.svg";
import locationPinFilled from "@/images/location-pin-filled.svg";
import locationPinOutline from "@/images/location-pin-outline.svg";
import longArrowRight from "@/images/long-arrow-right.svg";
import mailboxFilledWhite from "@/images/mailbox-filled-white.svg";
import mailboxFilled from "@/images/mailbox-filled.svg";
import mailboxWhite from "@/images/mailbox-white.svg";
import mailbox from "@/images/mailbox.svg";
import manualContactEntry from "@/images/manual contact entry.svg";
import mapIcon from "@/images/map.png";
import map from "@/images/map.svg";
import mashedLogo from "@/images/mashed@2x.png";
import mastercard from "@/images/mastercard.png";
import media1 from "@/images/media-1.png";
import media2 from "@/images/media-2.png";
import media3 from "@/images/media-3.png";
import media4 from "@/images/media-4.png";
import media5 from "@/images/media-5.png";
import media6 from "@/images/media-6.png";
import mediaContact from "@/images/media contact icon.svg";
import megaphoneIcon from "@/images/megaphone icon@2x.png";
import menu from "@/images/menu-icon.svg";
import milk from "@/images/milk icon.svg";
import miniCookies from "@/images/mini-cookies.png";
import minisBox from "@/images/minis-box.png";
import minisGifting from "@/images/minis-gifting.png";
import minisHero from "@/images/minis-hero.png";
import minusButton from "@/images/minus-button.svg";
import minusCircleWhite from "@/images/minus-circle-white.svg";
import minusCircle from "@/images/minus-circle.png";
import minus from "@/images/minus.svg";
import mobileUnfilled from "@/images/mobile-unfilled.png";
import modernRetailLogo from "@/images/modern retail logo.svg";
import mysteryCookie from "@/images/mystery-cookie-icon.svg";
import newGift from "@/images/new-gift.svg";
import onboarding from "@/images/onboarding-icon.svg";
import openConceptKitchen from "@/images/open-concept-kitchen@2x.png";
import packagingOptions from "@/images/packaging-options@2x.png";
import parentalLeave from "@/images/parental_leave-icon.png";
import partyOutline from "@/images/party-outline.svg";
import pause from "@/images/pause.svg";
import paymentDetails from "@/images/payment-details.svg";
import peanut from "@/images/peanut icon.svg";
import penIcon from "@/images/pen-icon.svg";
import pen from "@/images/pen.png";
import phoneFilled from "@/images/phone-filled.png";
import phone from "@/images/phone.png";
import phoneIcon from "@/images/phone_icon.png";
import pickup from "@/images/pick-up-icon.svg";
import pinkPickup from "@/images/pickup-icon-pink.svg";
import pinkAppScreenshot from "@/images/pink-app-screenshot.png";
import pinkBg from "@/images/pink-bg.png";
import pinkBoxClosed from "@/images/pink-box-closed.png";
import pinkClip from "@/images/pink-clip.png";
import pinkSquiggleBackgroundDesktop from "@/images/pink-squiggle-background-desktop.svg";
import pinkSquiggleBackgroundMobile from "@/images/pink-squiggle-background-mobile.svg";
import pinkWorld from "@/images/pink-world.png";
import pinkSquiggle from "@/images/pinkSquiggle.svg";
import pinterestLogo from "@/images/pinterest.png";
import playButton from "@/images/play-button.svg";
import play from "@/images/play.svg";
import plusButton from "@/images/plus-button.svg";
import plusCircleWhite from "@/images/plus-circle-white.svg";
import plusCircle from "@/images/plus-circle.png";
import plus from "@/images/plus-icon.svg";
import poinsettiaSm from "@/images/poinsettia-sm.png";
import poinsettia from "@/images/poinsettia.png";
import poinsettiaSmFlipped from "@/images/pointsettia-sm-flipped.png";
import presents from "@/images/presents icon.svg";
import printer from "@/images/printer.svg";
import pto from "@/images/pto-icon.png";
import puertoRicoSM from "@/images/puertorico-sm.png";
import purchaseHistory from "@/images/purchase-history.svg";
import pymntsLogo from "@/images/pymnts.com logo@2x.png";
import qsrLogo from "@/images/qsr logo@2x.png";
import questionMark from "@/images/question-mark.svg";
import rateApp from "@/images/rate-app.svg";
import taskRateApp from "@/images/rate the app icon.svg";
import receiptBottom from "@/images/receipt-bottom.png";
import receiptTop from "@/images/receipt-top.png";
import relationships1 from "@/images/relationships1.png";
import relationships2 from "@/images/relationships2.png";
import relationships3 from "@/images/relationships3.png";
import relationships4 from "@/images/relationships4.png";
import relationships5 from "@/images/relationships5.png";
import repeatFilledWhite from "@/images/repeat-filled-white.svg";
import repeatFilled from "@/images/repeat-filled.svg";
import repeatWhite from "@/images/repeat-white.svg";
import repeat from "@/images/repeat.svg";
import ribbon from "@/images/ribbon.png";
import pinkRoi from "@/images/roi-icon-pink.svg";
import rotatingMenuBottomMobile from "@/images/rotating-menu-bottom-mobile.png";
import rotatingMenuBottom from "@/images/rotating-menu-bottom.png";
import rotatingMenuTopMobile from "@/images/rotating-menu-top-mobile.png";
import rotatingMenuTop from "@/images/rotating-menu-top.png";
import safe from "@/images/safe.png";
import sampleCreditCard from "@/images/sample-cc.png";
import sampleVideoMessage from "@/images/sample-video-message@2x.png";
import sampleStore from "@/images/sample store image.png";
import savedAddresses from "@/images/saved-addresses.svg";
import scanCoupon from "@/images/scan-coupon.svg";
import scanGiftCard from "@/images/scan-giftcard.svg";
import scanQR from "@/images/scan-qr-icon.svg";
import schedule from "@/images/schedule.svg";
import search from "@/images/search-icon.svg";
import searchIcon from "@/images/search.png";
import seasonalFlavorBundle from "@/images/seasonal-flavor-bundle.png";
import selectFlavors from "@/images/select-flavors.svg";
import selectWhenWhere from "@/images/select-when-where.png";
import share from "@/images/share-icon.svg";
import shippingIconPink from "@/images/shipping-icon-mailbox-pink.svg";
import pinkShipping from "@/images/shipping-icon-pink.svg";
import shirtOutline from "@/images/shirt-outline.png";
import shoppingBagWhite from "@/images/shopping-bag-alt.svg";
import shoppingBagBlackOutline from "@/images/shopping-bag-black-outline.svg";
import shoppingBag from "@/images/shopping-bag-slim.png";
import shoppingBagWhiteOutline from "@/images/shopping-bag-white-outline.png";
import signDirectionRightUnfilled from "@/images/sign-direction-right-unfilled.png";
import signDirectionRight from "@/images/sign-direction-right.png";
import baker from "@/images/signbakercircle.png";
import skinnyCheck from "@/images/skinny-check.png";
import skinnyPlus from "@/images/skinny-plus.png";
import slotMachine from "@/images/slot-machine@2x.png";
import smMinusCircle from "@/images/sm-minus-circle.png";
import smPlusCircle from "@/images/sm-plus-circle.png";
import smsDeals from "@/images/sms-deals.svg";
import snowFlake from "@/images/snowflake-regular.png";
import socialFB from "@/images/social-fb.svg";
import socialInstagramDark from "@/images/social-instagram-dark.svg";
import socialInstagramLogo from "@/images/social-instagram.svg";
import socialTwitterLogo from "@/images/social-twitter.svg";
import soy from "@/images/soy icon.svg";
import sparkle from "@/images/sparkle.png";
import spatula from "@/images/spatula-icon.svg";
import sponsorshipIcon from "@/images/sponsorship-icon.png";
import stack from "@/images/stack.jpg";
import starTag from "@/images/star-tag.png";
import states from "@/images/states-icon.svg";
import { default as pinkStore, default as storeIcon } from "@/images/store-icon.svg";
import storeSmall from "@/images/store.png";
import store from "@/images/store.svg";
import store2FilledWhite from "@/images/store2-filled-white.svg";
import store2Filled from "@/images/store2-filled.svg";
import store2White from "@/images/store2-white.svg";
import store2 from "@/images/store2.svg";
import storeCatering from "@/images/store catering.png";
import cnbcLogo2 from "@/images/stores-page/cnbc-logo.png";
import cookieDunk from "@/images/stores-page/cookie-dunk@2x.png";
import cookieLineup from "@/images/stores-page/cookie-lineup@2x.png";
import cookiePull from "@/images/stores-page/cookie-pull@2x.png";
import cookieShadow from "@/images/stores-page/cookie-shadow@2x.png";
import gmaLogo2 from "@/images/stores-page/gma-logo.png";
import mondelezLogo from "@/images/stores-page/mondelez-logo.png";
import nrnLogo from "@/images/stores-page/nrn-logo.png";
import qsrLogo2 from "@/images/stores-page/qsr-logo.png";
import stevieAwardLogo from "@/images/stores-page/stevie-award-logo.jpg";
import subscriptionIcon from "@/images/subscription-icon.svg";
import subscriptionIphoneSplatBackground from "@/images/subscription-iphone-splat-background.png";
import subscriptionHeader from "@/images/subscriptions-header.png";
import subtractButton from "@/images/subtract-button.svg";
import suggestion from "@/images/suggestion icon.svg";
import summer from "@/images/summer-icon.png";
import support from "@/images/support icon.svg";
import thankyou from "@/images/survey-thankyou.svg";
import survey from "@/images/survey icon.svg";
import sweetBonus from "@/images/sweet-bonus.png";
import tapCrumblCard from "@/images/tapping-the-crumbl-card-icon.svg";
import tasteWeekly from "@/images/taste-weekly.png";
import textSupport from "@/images/text-support-icon.svg";
import thumbsDown from "@/images/thumbs-down.svg";
import thumbsUp from "@/images/thumbs-up.svg";
import carryOutTicket from "@/images/ticket/carry-out icon black.svg";
import grubHubTicket from "@/images/ticket/grub hub icon.svg";
import cateringTicket from "@/images/ticket/ticket catering icon.svg";
import curbsideTicket from "@/images/ticket/ticket curbside order icon.svg";
import deliveryTicket from "@/images/ticket/ticket delivery order icon.svg";
import doordashTicket from "@/images/ticket/ticket doordash icon.svg";
import driveThruTicket from "@/images/ticket/ticket drive thru icon.svg";
import kioskTicket from "@/images/ticket/ticket kiosk order icon.svg";
import instoreTicket from "@/images/ticket/ticket pos order icon.svg";
import uberEatsTicket from "@/images/ticket/uber eats logo.svg";
import tiktokButton from "@/images/tiktok-button.png";
import tiktok from "@/images/tiktok-icon-sm.png";
import socialTiktok from "@/images/tiktok-icon.svg";
import tiktokLogo from "@/images/tiktokicon.svg";
import time from "@/images/time-icon.svg";
import timesCircle from "@/images/times-circle.png";
import trash from "@/images/trash.svg";
import travelFunds from "@/images/travel_funds-icon.png";
import treeNut from "@/images/treenut icon.svg";
import truckFilledWhite from "@/images/truck-filled-white.svg";
import truckFilled from "@/images/truck-filled.svg";
import truckWhite from "@/images/truck-white.svg";
import truck from "@/images/truck.svg";
import tv from "@/images/tv.svg";
import twitterButton from "@/images/twitter-button.png";
import socialTwitter from "@/images/twitter-icon.svg";
import twitterLogo from "@/images/twitter.svg";
import twoCrazyCousins from "@/images/two-crazy-cousins.png";
import uniqueFlavors from "@/images/unique-flavors.png";
import userOutline from "@/images/user-outline.png";
import userWhite from "@/images/user-white.png";
import pinkUsers from "@/images/users-icon-pink.svg";
import carConvertible from "@/images/v2/car-convertible.png";
import carHatchback from "@/images/v2/car-hatchback.png";
import carJeep from "@/images/v2/car-jeep.png";
import carSedan from "@/images/v2/car-sedan.png";
import carSportscar from "@/images/v2/car-sportscar.png";
import carSuv from "@/images/v2/car-suv.png";
import carTruck from "@/images/v2/car-truck.png";
import carVan from "@/images/v2/car-van.png";
import receiptBottomV2 from "@/images/v2/receiptBottomV2.png";
import receiptTopV2 from "@/images/v2/receiptTopV2.png";
import valuesBgBottom from "@/images/values-bottom.png";
import valuesBgTop from "@/images/values-top.png";
import visaOutline from "@/images/visa-outline.png";
import visa from "@/images/visa.png";
import voucher from "@/images/voucher-icon.svg";
import wallet from "@/images/wallet.png";
import warmer from "@/images/warmer.svg";
import weeklyFlavorsBundle from "@/images/weekly-flavors-bundle.png";
import weeklyMenuBottom from "@/images/weekly-menu-bottom.png";
import weeklyMenuTop from "@/images/weekly-menu-top.png";
import weeklyRotatingMenuPreview from "@/images/weekly-rotating-menu-preview.png";
import weeklyRotatingMenu from "@/images/weekly-rotating-menu@2x.png";
import wheat from "@/images/wheat icon.svg";
import whereItAllBegan from "@/images/where it all began@3x.png";
import whiteArrowdown from "@/images/white-arrowdown.png";
import whiteCateringIcon from "@/images/white-catering-icon-outlined.svg";
import whiteCurbsideIcon from "@/images/white-curbside-icon-outlined.svg";
import whiteDeliveryIcon from "@/images/white-delivery-icon-outlined.svg";
import facebookLogoWhite from "@/images/white-facebook-logo.png";
import whiteGiftcardIcon from "@/images/white-gift-card-icon-outlined.svg";
import instagramLogoWhite from "@/images/white-instagram-icon.svg";
import whiteShippingIcon from "@/images/white-shipping-icon-outlined.svg";
import whiteSubscriptionsIcon from "@/images/white-subscriptions-icon-outlined.svg";
import twitterLogoWhite from "@/images/white-twitter-icon.svg";
import whiteWarning from "@/images/white-warning-icon.png";
import whoWeAre from "@/images/who-we-are.png";
import youtubeButton from "@/images/youtube-button.png";
import youtube from "@/images/youtube-icon-sm.png";
import socialYoutube from "@/images/youtube-icon.svg";
import youtubeLogo from "@/images/youtube_icon.svg";
import easterWebBanner from "./Easter_Hop-To-It_Web-Banner.png";
import butterCakeBanner from "./butter-cake-banner.png";
import cookieDoughBitsBanner from "./cookie-dough-bits-banner.png";

// V2 imports, keep separate so we can cleanup unused v1 images later

// we have these as images because we cannot render components in our map view.
import playStoreBadge from "@/images/badge-play-store.png";
import clockRegular from "@/images/clock.svg";
import downloadAppStore from "@/images/download app store.png";
import mobileRetular from "@/images/mobile.svg";
import moon from "@/images/moon.svg";
// input search icon
import searchRegularIcon from "@/images/search-regular.svg";
import storeRegular from "@/images/store.svg";
import pencilEdit from "@/images/v2/pencil-edit.svg";
import waveLarge from "@/images/wave-lg.png";
import waveSmall from "@/images/wave-sm.png";
import waveXSmall from "@/images/wave-xs.png";
import fourPack from "./v2/4-pack.png";
import sixPackWithGiftWrap from "./v2/6-pack-with-giftwrap.svg";
import illustrationBakerHoldingBoxes from "./v2/Baker-Holding-Boxes.png";
import jobImage2 from "./v2/CTF_GrandRe-Opening 013.jpg";
import jobImage3 from "./v2/CTF_GrandRe-Opening 021.jpg";
import jobImage4 from "./v2/CTF_GrandRe-Opening 198.jpg";
import jobImage1 from "./v2/CTF_GrandRe-Opening 200.jpg";
import fourPackAngleWhite from "./v2/Crumbl_BoxRefresh_4Pack_Angle_White.png";
import delivery from "./v2/Delivery.png";
import keyLimePhoneMockup from "./v2/Digital_Ads_Delivery_ASSET_PhoneMockup_KeyLime.png";
import experienceConference from "./v2/ExperienceConference.jpg";
import firstStorePicture from "./v2/FirstStorePicture.jpg";
import collabHero from "./v2/Frame 9845.png";
import illustrationGuyOnPhone from "./v2/Guy-on-phone-with-bubbles.png";
import htpMockup from "./v2/HTP-mockup.png";
import huntsmanResearch from "./v2/HuntsmanResearch.jpg";
import milkChocolateChipStyled12 from "./v2/MilkChocolateChip_Styled_12.jpg";
import newHQBuilding from "./v2/NewHQBuilding.jpg";
import OR from "./v2/OR.png";
import OrderDownloadAppMockup from "./v2/Order_DownloadAppMockup.png";
import pinkVelvetCake from "./v2/PinkVelvetCake.jpg";
import pinkMilkChocolateChip from "./v2/Pink_MilkChocolateChip_OverheadAerial.png";
import birthdayCake from "./v2/birthday-cake.svg";
import blackCircle from "./v2/black-circle.svg";
import brandPartnerships from "./v2/brandPartnerships.png";
import cateringGallery1 from "./v2/catering1.jpg";
import cateringGallery2 from "./v2/catering2.jpg";
import cateringGallery3 from "./v2/catering3.jpg";
import cateringGallery4 from "./v2/catering4.jpg";
import cateringGallery5 from "./v2/catering5.jpg";
import cateringGallery6 from "./v2/catering6.jpg";
import cateringGallery7 from "./v2/catering7.jpg";
import cateringGallery8 from "./v2/catering8.jpg";
import cateringGallery9 from "./v2/catering9.jpg";
import cateringGallery10 from "./v2/catering10.jpg";
import cateringGallery11 from "./v2/catering11.jpg";
import cellphone from "./v2/cellphone.svg";
import checkCircleWhite from "./v2/check-circle.svg";
import checkCircleBlack from "./v2/check-circle-black.svg";
import checker from "./v2/checker.png";
import chocolateChipCookieWithChips from "./v2/chocolate-chip-cookie-with-chips.png";
import classicPinkSugar from "./v2/classic-pink-sugar.jpg";
import classicPinkSugarCookies from "./v2/classicPinkSugarCookies.jpg";
import cookieOverheadAerial from "./v2/cookie-overhead-aerial.png";
import cookieOverhead from "./v2/cookie-overhead.png";
import crumblCaresLogo from "./v2/crumbl-cares-logo.png";
import crumblWorld from "./v2/crumbl-world.png";
import crumblBox from "./v2/crumblBox.jpg";
import crumblCateringHero from "./v2/crumblCateringHero.png";
import email from "./v2/email.svg";
import executiveTeam from "./v2/executiveTeam.jpg";
import firstStoreOwners from "./v2/first-store-owners.png";
import freeCookieDay from "./v2/free-cookie-day.png";
import gentri from "./v2/gentri.png";
import giftWrapBow from "./v2/giftwrap-illustration-bow.png";
import giftWrap from "./v2/giftwrap.svg";
import illustrationChristmas from "./v2/illustration-christmas.png";
import illustrationCookiePillow from "./v2/illustration-cookie-pillow.png";
import illustrationCustomerSupport from "./v2/illustration-customer-support.png";
import illustrationFamily from "./v2/illustration-family.png";
import illustrationFrosting from "./v2/illustration-frosting.png";
import illustrationGiftingBow from "./v2/illustration-gifting-bow.svg";
import illustrationGrandOpening from "./v2/illustration-grand-opening.png";
import illustrationJasonAndSawyer from "./v2/illustration-jason-and-sawyer.png";
import illustrationMeaningfulMoments from "./v2/illustration-meaningful-moments.png";
import illustrationMilkChocolateChip from "./v2/illustration-milk-chocolate-chip.png";
import illustrationOrderTypeCarryout from "./v2/illustration-order-type-carryout.png";
import illustrationOrderTypeCatering from "./v2/illustration-order-type-catering.png";
import illustrationOrderTypeCurbside from "./v2/illustration-order-type-curbside.png";
import illustrationOrderTypeDelivery from "./v2/illustration-order-type-delivery.png";
import illustrationOrderTypeDriveThru from "./v2/illustration-order-type-drive-thru.png";
import illustrationOrderTypePickup from "./v2/illustration-order-type-pickup.png";
import illustrationPartyBox from "./v2/illustration-party-box.png";
import illustrationPinkBlob from "./v2/illustration-pink-blob.png";
import illustrationSpilledBox from "./v2/illustration-spilled-box.png";
import illustrationStore4x from "./v2/illustration-store-4x.png";
import illustrationBakersFruitPizza from "./v2/illustrationBakersFruitPizza.png";
import illustrationBigLime from "./v2/illustrationBig-Lime.png";
import illustrationCookieStack from "./v2/illustrationCookie-Stack.png";
import illustrationDreamingCookie from "./v2/illustrationDreamingCookie.png";
import illustrationPinkBoxWithApple from "./v2/illustrationPink-Box-with-Apple.png";
import illustrationSkateboardCookie from "./v2/illustrationSkateboardCookie.png";
import illustrationWorkerAtCounter from "./v2/illustrationWorkerAtCounter.png";
import jasonAndSawyer from "./v2/jasonAndSawyer.jpg";
import keyLimePie from "./v2/key-lime-pie.jpg";
import cateringHeroLg from "./v2/lgCateringHero.png";
import lgtrc from "./v2/lgrtc.png";
import loyaltyTierBronze from "./v2/loyaltyTierBronze.svg";
import loyaltyTierGold from "./v2/loyaltyTierGold.svg";
import loyaltyTierPink from "./v2/loyaltyTierPink.svg";
import loyaltyTierSilver from "./v2/loyaltyTierSilver.svg";
import modernRetailArticleImage from "./v2/modernRetailArticleImage.png";
import pinkBox1 from "./v2/pink-box1.png";
import pinkBox2 from "./v2/pink-box2.png";
import pinkBox3 from "./v2/pink-box3.jpg";
import pinkBox4 from "./v2/pink-box4.jpg";
import pinkBox5 from "./v2/pink-box5.png";
import pinkBox6 from "./v2/pink-box6.png";
import pinkSwirlLg from "./v2/pinkswirl-lg.svg";
import pinkSwirl from "./v2/pinkswirl.svg";
import rewardsWithCookie from "./v2/rewardsWithCookie.png";
import sampleStoreFront from "./v2/sample-store-front.png";
import todayLogo from "./v2/todayLogo.png";
import trashBin from "./v2/trash-bin.svg";
import crumblCaresLogo2 from "./v2/CrumblCares-logo.png";
import crumblCaresPhoto from "./v2/crumbl-cares-photo.jpg";
import RecipeForSuccess from "./v2/RecipeForSuccess.jpg";
import mayContainIndicator from "./v2/mayContainIndicator.svg";
import containsIndicator from "./v2/containsIndicator.svg";

export const Icons = {
  clockRegular,
  mobileRetular,
  moon,
  storeRegular,
  addButton,
  addFunds,
  addRecipient,
  alarmClock,
  alarmClockBlack,
  amex,
  android,
  androidWhite,
  angleDown,
  angleRightSmall,
  apple,
  appleWhite,
  arrowDown,
  arrowExport,
  arrowLeft,
  arrowRight,
  arrowRightSmall,
  arrowUp,
  baker,
  birthdayBalloons,
  blackArrowDown,
  blackPlus,
  blackStar,
  boxing,
  briefCase,
  CAFlag,
  calendar,
  calendarStar,
  car,
  carFilled,
  carFilledWhite,
  carryOutTicket,
  carWhite,
  cashIconWhite,
  cateringIcon,
  cateringTicket,
  checkboxActive,
  checkCircle,
  checkMark,
  checkmarkCompleted,
  checkMarkHuge,
  checkMarkIcon,
  checkWhite,
  chefHat,
  childcareReimbursement,
  cities,
  clipboard,
  clipboardText,
  clock,
  close,
  closeButton,
  closeWhite,
  completePurchase,
  connectedArrow,
  construction,
  contactDetails,
  contactList,
  cookie,
  cookieChoice,
  cookieHand,
  cookieOutlineIcon,
  cookieSingleIcon,
  cookieStackIcon,
  copy,
  createGift,
  creditCard,
  crumb,
  crumblCard,
  crumblCares,
  crumblCash,
  crumblCashCelebrate,
  crumblLogo,
  crumbsCelebrate,
  csvDownload,
  csvUpload,
  csvUploadSmall,
  curbsideIcon,
  curbsidePinkIcon,
  curbsideTicket,
  date,
  delete: deleteIcon,
  deleteAddress,
  deliveryIcon,
  deliveryPinkIcon,
  deliveryTicket,
  deliveryVan,
  deliveryVanDark,
  desktop,
  discountsCrumblcash,
  discover,
  doordashTicket,
  downloadApp,
  downloadAppStore,
  downloadArrow,
  dressing,
  driveThruTicket,
  dropletOutline,
  earnFacebook,
  earnInstagram,
  earnLinkedIn,
  earnPinterest,
  earnTiktok,
  earnTwitter,
  earnYoutube,
  edit,
  editCircle,
  egg,
  eggImage,
  emailSignup,
  enjoyCookies,
  exclamationCircle,
  facebookButton,
  facebookLogo,
  facebookLogoWhite,
  facebookReviewLogo,
  filledSparkle,
  filledTruck,
  filledTruckBlack,
  foodTray,
  foodTrayFilled,
  foodTrayFilledWhite,
  foodTrayWhite,
  freeCrumbCookie,
  fridge,
  fundraisersIcon,
  fundraisingIcon,
  gift,
  giftActivity,
  giftcard,
  giftcardFilled,
  giftcardFilledWhite,
  giftcardGiftPinkIcon,
  giftcardIcon,
  giftcardPinkIcon,
  giftcardQRCode,
  giftcardWhite,
  giftFilled,
  giftFilledWhite,
  giftOutline,
  giftWhite,
  googleReviewLogo,
  grandOpening,
  greyCash,
  greyCreditDebit,
  greyCrumblCash,
  greyEdit,
  greyGiftcard,
  greyInvoice,
  greyLocation,
  greyPlus,
  grubHubTicket,
  hamburger,
  handHoldingCookieFilledWhite,
  handHoldingCookieRegular,
  handPie,
  handPieSilhouette,
  handshake,
  hatChef,
  hatChefSmall,
  healthCoverage,
  home,
  homeWhite,
  hours,
  i401k,
  icecreamScoop,
  info,
  infoCircle,
  instagram,
  instagramButton,
  instagramLogo,
  instagramLogoWhite,
  instoreTicket,
  interview,
  inventoryMinus,
  kioskTicket,
  leaveReview,
  lineCatering,
  lineCurbside,
  lineDelivery,
  lineDriveThru,
  linkedInLogo,
  listIcon,
  location,
  locationPin,
  locationPinFilled,
  locationPinOutline,
  longArrowRight,
  mailbox,
  mailboxFilled,
  mailboxFilledWhite,
  mailboxWhite,
  manualContactEntry,
  mapIcon,
  mastercard,
  mediaContact,
  megaphoneIcon,
  menu,
  milk,
  minus,
  minusButton,
  minusCircle,
  minusCircleWhite,
  mobileUnfilled,
  mysteryCookie,
  newGift,
  onboarding,
  parentalLeave,
  partyOutline,
  pause,
  paymentDetails,
  peanut,
  pen,
  penIcon,
  phone,
  phoneFilled,
  phoneIcon,
  pickup,
  pinkCalendar,
  pinkCash,
  pinkCatering,
  pinkCookie,
  pinkDelivery,
  pinkGiftcard,
  pinkLocationPin,
  pinkPickup,
  pinkRoi,
  pinkShipping,
  pinkSquiggle,
  pinkStore,
  pinkUsers,
  pinterestLogo,
  play,
  playButton,
  playStoreBadge,
  plus,
  plusButton,
  plusCircle,
  plusCircleWhite,
  poinsettia,
  poinsettiaSm,
  poinsettiaSmFlipped,
  presents,
  printer,
  pto,
  purchaseHistory,
  qrCode,
  questionMark,
  rateApp,
  repeat,
  repeatFilled,
  repeatFilledWhite,
  repeatWhite,
  ribbon,
  safe,
  savedAddresses,
  scanCoupon,
  scanGiftCard,
  scanQR,
  schedule,
  search,
  searchIcon,
  searchRegularIcon,
  selectFlavors,
  share,
  shippingIcon,
  shippingIconPink,
  shirtOutline,
  shoppingBag,
  signDirectionRight,
  signDirectionRightUnfilled,
  skinnyCheck,
  skinnyPlus,
  smMinusCircle,
  smPlusCircle,
  smsDeals,
  snowFlake,
  socialFacebook,
  socialFB,
  socialInstagram,
  socialInstagramDark,
  socialInstagramLogo,
  socialTiktok,
  socialTwitter,
  socialTwitterLogo,
  socialYoutube,
  soy,
  sparkle,
  spatula,
  sponsorshipIcon,
  starTag,
  states,
  store,
  store2,
  store2Filled,
  store2FilledWhite,
  store2White,
  storeIcon,
  storeSmall,
  subscriptionIcon,
  subtractButton,
  suggestion,
  summer,
  support,
  survey,
  taskAddBirthday,
  taskApp,
  taskEmailSignup,
  taskFacebook,
  taskInstagram,
  taskLeaveReview,
  taskLinkedIn,
  taskPinterest,
  taskRateApp,
  taskTiktok,
  taskTwitter,
  taskYoutube,
  textSupport,
  thumbsDown,
  thumbsUp,
  tiktok,
  tiktokButton,
  tiktokLogo,
  time,
  timesCircle,
  trash,
  travelFunds,
  treeNut,
  truck,
  truckFilled,
  truckFilledWhite,
  truckWhite,
  tv,
  twitterButton,
  twitterLogo,
  twitterLogoWhite,
  uberEatsTicket,
  UKFlag,
  userOutline,
  userWhite,
  USFlag,
  visa,
  visaOutline,
  voucher,
  wallet,
  warmer,
  wheat,
  whiteArrowdown,
  whiteCateringIcon,
  whiteCurbsideIcon,
  whiteDeliveryIcon,
  whiteGiftcardIcon,
  whiteShippingIcon,
  whiteSubscriptionsIcon,
  whiteWarning,
  yelpReviewLogo,
  youtube,
  youtubeButton,
  youtubeLogo,
  containsIndicator,
  mayContainIndicator,
};

export const Images = {
  CACookies,
  CAHomepageCookies,
  CALine1,
  CALine2,
  CALine3,
  CAOwners,
  CASS1,
  CASS2,
  CASS3,
  CASS4,
  CookieMapArrow,
  CookiesLargerThanLife,
  CookiesLargerThanLife_large,
  CrumblOntarioOwners,
  FreeCookieDay2,
  FreeCookieDay2_large,
  FreeCookies,
  FreeCookies_large,
  PinkParty,
  PinkParty_large,
  Rectangle4880,
  Rectangle4882,
  Rectangle4883,
  Rectangle4884,
  Rectangle4885,
  Rectangle4886,
  Rectangle4887,
  abcNewsLogo,
  appArrow,
  appScreenshot,
  appScreenshotSm,
  authenticity1,
  authenticity2,
  authenticity3,
  authenticity4,
  authenticity5,
  bannerFreeDelivery,
  bannerHolidayCatering,
  bannerWonka,
  bayamonOwners,
  birthdayIllustration,
  blackFridayWebBanner,
  bringingPeopleTogether,
  butterCakeBanner,
  canadaMilkCC,
  careersHero,
  careersSeparator,
  catering1,
  catering2,
  catering3,
  catering4,
  catering5,
  catering6,
  catering7,
  catering8,
  catering9,
  cateringBox,
  cateringHero,
  cateringHeroSm,
  cateringLine,
  cateringLineup,
  cateringScreenshot,
  cateringWhenWhere,
  cchand,
  chocolateChipTallStack,
  cinnamonSquareWebBanner,
  closedSign,
  cnbcLogo,
  cnbcLogo2,
  collab,
  collabLarge,
  cookieComposition,
  cookieCompositionMobile,
  cookieDoughBitsBanner,
  cookieDunk,
  cookieLineup,
  cookiePull,
  cookieShadow,
  coverYourCookiesSm,
  creamBg,
  createYourOwnBundle,
  crewImage,
  crewImage1,
  crewImage2,
  crewImage3,
  crewImage4,
  crewImage5,
  crewImage6,
  crewImage7,
  crewImage8,
  crewImage9,
  crumblCardSampleUsage,
  crumblCertification,
  crumblCookiesLogo,
  crumblFounders,
  crumblLogoLight,
  crumblStory,
  crumbsToCash,
  customerSupportMobile,
  cyberMondayWebBanner,
  detailsImage,
  digitalGiftCard,
  easterWebBanner,
  emailCookies,
  emailCookiesSmall,
  experiences1,
  experiences2,
  experiences3,
  experiences4,
  experiences5,
  famousPinkBox,
  famousPinkBoxClipped,
  fan1,
  fan10,
  fan11,
  fan2,
  fan3,
  fan4,
  fan5,
  fan6,
  fan7,
  fan8,
  fan9,
  firstStore,
  flagHand,
  forbesLogo,
  freeCookieCardMockup,
  fullLogo,
  fundraisersHeroImage,
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  gallery5,
  gallery6,
  gallery7,
  giftcards,
  giftwrapSample,
  giftwrappedBox,
  gmaLogo2,
  goodMorningAmericaLogo,
  homeSweetHome,
  innovation1,
  innovation2,
  innovation3,
  innovation4,
  innovation5,
  ipadScreenshot,
  iphoneMockupsCombined,
  iphoneOverlay,
  iphoneSubscriptions,
  joinCrew,
  keyLimePieWebBanner,
  landingPageHero,
  landingPageHeroMobile,
  largeStoreHero,
  largerThanLifeMap,
  logo,
  loyaltyImage,
  map,
  mashedLogo,
  media1,
  media2,
  media3,
  media4,
  media5,
  media6,
  miniCookie,
  miniCookies,
  minisBox,
  minisGifting,
  minisHero,
  minisWeekWebBanner,
  modernRetailLogo,
  mondelezLogo,
  nrnLogo,
  openConceptKitchen,
  packagingOptions,
  phoneMockups,
  pinkAppScreenshot,
  pinkBg,
  pinkBoxClosed,
  pinkClip,
  pinkSquiggleBackgroundDesktop,
  pinkSquiggleBackgroundMobile,
  pinkWorld,
  puertoRicoSM,
  pymntsLogo,
  qsrLogo,
  qsrLogo2,
  receiptTop,
  regularCookie,
  relationships1,
  relationships2,
  relationships3,
  relationships4,
  relationships5,
  rotatingMenuBottom,
  rotatingMenuBottomMobile,
  rotatingMenuTop,
  rotatingMenuTopMobile,
  sampleCreditCard,
  sampleStore,
  sampleVideoMessage,
  seasonalFlavorBundle,
  selectWhenWhere,
  shoppingBagBlackOutline,
  shoppingBagWhite,
  shoppingBagWhiteOutline,
  slotMachine,
  stPatricksDayWebBanner,
  stack,
  stevieAwardLogo,
  storeCatering,
  storeHero,
  subscriptionHeader,
  subscriptionIphoneSplatBackground,
  superBowlWebBanner,
  sweetBonus,
  tapCrumblCard,
  tasteWeekly,
  thankyou,
  tresLechesCake,
  twoCrazyCousins,
  uniqueFlavors,
  valuesBgBottom,
  valuesBgTop,
  weeklyFlavorsBundle,
  weeklyMenuBottom,
  weeklyMenuTop,
  weeklyRotatingMenu,
  weeklyRotatingMenuPreview,
  whereItAllBegan,
  whoWeAre,
};

// Images known to be in use with the brand refresh. Only include images here
// if they are included in new designs so we know what we can clean up later.
export const ImagesV2 = {
  OR,
  OrderDownloadAppMockup,
  RecipeForSuccess,
  abcNewsLogo,
  blackCircle,
  brandPartnerships,
  carConvertible,
  carHatchback,
  carJeep,
  carSedan,
  carSportscar,
  carSuv,
  carTruck,
  carVan,
  cateringGallery1,
  cateringGallery10,
  cateringGallery11,
  cateringGallery2,
  cateringGallery3,
  cateringGallery4,
  cateringGallery5,
  cateringGallery6,
  cateringGallery7,
  cateringGallery8,
  cateringGallery9,
  cateringHeroLg,
  checkCircleBlack,
  checkCircleWhite,
  checker,
  chocolateChipCookieWithChips,
  classicPinkSugar,
  classicPinkSugarCookies,
  cnbcLogo,
  collabHero,
  cookieOverhead,
  cookieOverheadAerial,
  crumblBox,
  crumblCaresLogo,
  crumblCaresLogo2,
  crumblCaresPhoto,
  crumblCateringHero,
  crumblWorld,
  delivery,
  downloadAppStore,
  executiveTeam,
  experienceConference,
  firstStoreOwners,
  firstStorePicture,
  fourPack,
  fourPackAngleWhite,
  freeCookieDay,
  gentri,
  giftWrap,
  giftWrapBow,
  goodMorningAmericaLogo,
  htpMockup,
  huntsmanResearch,
  illustrationBakerHoldingBoxes,
  illustrationBakersFruitPizza,
  illustrationBigLime,
  illustrationChristmas,
  illustrationCookiePillow,
  illustrationCookieStack,
  illustrationCustomerSupport,
  illustrationDreamingCookie,
  illustrationFamily,
  illustrationFrosting,
  illustrationGiftingBow,
  illustrationGrandOpening,
  illustrationGuyOnPhone,
  illustrationJasonAndSawyer,
  illustrationMeaningfulMoments,
  illustrationMilkChocolateChip,
  illustrationOrderTypeCarryout,
  illustrationOrderTypeCatering,
  illustrationOrderTypeCurbside,
  illustrationOrderTypeDelivery,
  illustrationOrderTypeDriveThru,
  illustrationOrderTypePickup,
  illustrationPartyBox,
  illustrationPinkBlob,
  illustrationPinkBoxWithApple,
  illustrationSkateboardCookie,
  illustrationSpilledBox,
  illustrationStore4x,
  illustrationWorkerAtCounter,
  jasonAndSawyer,
  jobImage1,
  jobImage2,
  jobImage3,
  jobImage4,
  keyLimePhoneMockup,
  keyLimePie,
  lgtrc,
  loyaltyTierBronze,
  loyaltyTierGold,
  loyaltyTierPink,
  loyaltyTierSilver,
  milkChocolateChipStyled12,
  modernRetailArticleImage,
  modernRetailLogo,
  newHQBuilding,
  pinkBox1,
  pinkBox2,
  pinkBox3,
  pinkBox4,
  pinkBox5,
  pinkBox6,
  pinkMilkChocolateChip,
  pinkSwirl,
  pinkSwirlLg,
  pinkVelvetCake,
  playStoreBadge,
  qsrLogo,
  receiptBottom,
  receiptBottomV2,
  receiptTop,
  receiptTopV2,
  rewardsWithCookie,
  sampleStoreFront,
  sixPackWithGiftWrap,
  todayLogo,
  waveLarge,
  waveSmall,
  waveXSmall,
};

export const IconsV2 = {
  birthdayCake,
  cellphone,
  email,
  pencilEdit,
  trashBin,
};
