import Text from "@/components/v2/atoms/Text/Text";
import {
  type Order,
  type ValidateFulfillmentTimeResult,
  ValidateFulfillmentTimeResultType,
} from "@/generated/requests/pos";
import { getLabelForDate } from "@/lib/date";
import { formatMoney } from "@/static/lib/util";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import IconAlarmEdit from "../../atoms/Icons/AlarmEdit";
import IconAlarmPlus from "../../atoms/Icons/AlarmPlus";
import IconCalendarEdit from "../../atoms/Icons/CalendarEdit";
import IconNoteListCheck from "../../atoms/Icons/NoteListCheck";
import IconPenEdit from "../../atoms/Icons/PenEdit";
import Button from "../../molecules/Button/Button";
import MobileDateWheelPicker from "../../molecules/MobileDateWheelPicker/MobileDateWheelPicker";
import Modal from "../../molecules/Modal/Modal";
import { useGetStoreBySlug } from "../../operations/queries";
import Image from "next/image";
import IconCookieBoxImage from "@/images/v2/icon-cookie-box.png";
import { createDayJs } from "@/lib/date";

interface ExtraBakeTimeModalProps {
  isOpen: boolean;
  tempAdjustedFulfillmentTime: ValidateFulfillmentTimeResult;
  earliestAvailableFulfillmentTime: ValidateFulfillmentTimeResult;
  onClose: () => void;
  onClick: () => Promise<void>;
  fulfillmentModalDescription: string;
  setTempAdjustedFulfillmentTime: (time: ValidateFulfillmentTimeResult) => void;
  order: Order;
  isUpsertLoading: boolean;
  orderLoading: boolean;
  orderTimeSlot: string;
  tempBagTotal?: number;
  adjustFulfillmentTimeLoading: boolean;
}

const ExtraBakeTimeModal = ({
  isOpen,
  tempAdjustedFulfillmentTime,
  earliestAvailableFulfillmentTime,
  setTempAdjustedFulfillmentTime,
  onClose,
  onClick,
  fulfillmentModalDescription,
  order,
  isUpsertLoading,
  orderLoading,
  orderTimeSlot,
  tempBagTotal,
  adjustFulfillmentTimeLoading,
}: ExtraBakeTimeModalProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const storeSlug = router?.query?.slug as string;
  const isSameDay = tempAdjustedFulfillmentTime?.type === ValidateFulfillmentTimeResultType?.SameDay;
  const isNewWeek = tempAdjustedFulfillmentTime?.type === ValidateFulfillmentTimeResultType?.NewDayNextWeek;
  const isOverMaximumOrder = tempAdjustedFulfillmentTime?.type === ValidateFulfillmentTimeResultType?.OverMaximumForDay;
  const [editView, setEditView] = useState<boolean>(false);

  const { data: storeData, loading: storeDataLoading } = useGetStoreBySlug({ storeSlug });
  const dayjs = createDayJs({ timezone: storeData?.timezone });

  const [selectedDay, setSelectedDay] = useState(
    dayjs(tempAdjustedFulfillmentTime?.adjustedFullfillmentTime || orderTimeSlot)?.format("YYYY-MM-DD"),
  );
  const [selectedTimeslot, setSelectedTimeslot] = useState(
    tempAdjustedFulfillmentTime?.adjustedFullfillmentTime || orderTimeSlot,
  );

  const handleOnClick = async () => {
    if (isOverMaximumOrder) {
      // call the store
      window.location.href = `tel:${storeData?.phone}`;
    } else if (isNewWeek) {
      // TODO clear the cart and start over order
    } else {
      await onClick();
    }
  };

  const handleEditTime = () => {
    setTempAdjustedFulfillmentTime({ ...tempAdjustedFulfillmentTime, adjustedFullfillmentTime: selectedTimeslot });
    setEditView(false);
  };

  useEffect(() => {
    if (selectedDay && dayjs(tempAdjustedFulfillmentTime?.adjustedFullfillmentTime).isAfter(dayjs(selectedDay))) {
      setSelectedDay(dayjs(tempAdjustedFulfillmentTime?.adjustedFullfillmentTime)?.format("YYYY-MM-DD"));
      setSelectedTimeslot(tempAdjustedFulfillmentTime?.adjustedFullfillmentTime);
    }
  }, [tempAdjustedFulfillmentTime]);

  return (
    <Modal
      variant="secondaryNoDivider"
      isOpen={isOpen}
      onClose={onClose}
      className={isNewWeek || isOverMaximumOrder ? `!bg-primary-light` : `!bg-white`}
    >
      <div
        className={classNames(
          "flex flex-col my-auto sm:max-w-[366px] md:max-w-full h-full items-center justify-between",
          isOverMaximumOrder || isNewWeek ? "gap-[25px]" : "sm:min-h-[544px]",
        )}
      >
        <div className="flex flex-col gap-[10px] items-center justify-between">
          {editView ? (
            isSameDay ? (
              <IconAlarmEdit className="size-[80px]" />
            ) : (
              <IconCalendarEdit className="size-[80px]" />
            )
          ) : isOverMaximumOrder ? (
            <IconNoteListCheck className="size-[80px]" />
          ) : isNewWeek ? (
            <Image src={IconCookieBoxImage} alt="cookie box" width={258} height={170} />
          ) : (
            <IconAlarmPlus className="size-[80px]" />
          )}
          <div className="flex flex-col gap-[5px] items-center justify-between">
            <Text variant="title1">
              {editView
                ? isSameDay
                  ? t("order:adjust_pickup_time")
                  : t("order:adjust_pickup_date")
                : isOverMaximumOrder
                  ? t("order:call_for_custom_order")
                  : t("order:extra_time_to_bake")}
            </Text>
            <Text variant="body1" className="text-grey-60 text-center">
              {editView
                ? isSameDay
                  ? t("order:what_time_works")
                  : t("order:what_date_works")
                : isOverMaximumOrder
                  ? t("order:custom_order_description_x", { x: formatMoney(tempBagTotal) })
                  : fulfillmentModalDescription}
            </Text>
          </div>
        </div>
        {!isOverMaximumOrder && !isNewWeek && !editView && (
          <div className="flex items-center gap-2.5 justify-center">
            <Text variant="display3">
              {`${getLabelForDate(dayjs(tempAdjustedFulfillmentTime?.adjustedFullfillmentTime), storeData?.timezone)}, ${dayjs(tempAdjustedFulfillmentTime?.adjustedFullfillmentTime)?.format("h:mm a")}`}
            </Text>
            <div
              className="border border-solid border-grey-10 rounded-full p-3 cursor-pointer"
              onClick={() => setEditView(true)}
            >
              <IconPenEdit height={20} width={20} />
            </div>
          </div>
        )}
        {editView && (
          <MobileDateWheelPicker
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            selectedTimeslot={selectedTimeslot}
            setSelectedTimeslot={setSelectedTimeslot}
            earliestAvailableTime={earliestAvailableFulfillmentTime?.adjustedFullfillmentTime}
          />
        )}
        {editView ? (
          <div className="w-full flex justify-center">
            <Button className="w-full" onClick={handleEditTime}>
              {isSameDay ? t("order:save_new_time") : t("order:save_new_date_and_time")}
            </Button>
          </div>
        ) : (
          <div className="flex flex-col w-full gap-[15px] items-center">
            <Button
              className="w-full"
              onClick={handleOnClick}
              disabled={isUpsertLoading || orderLoading || adjustFulfillmentTimeLoading}
            >
              {isOverMaximumOrder
                ? t("order:call_x_store", { x: storeData?.name })
                : isNewWeek
                  ? t("order:start_over_order_new_flavors")
                  : t("order:that_works")}
            </Button>
            <Button variant="secondary" className="w-full" onClick={onClose} disabled={isUpsertLoading || orderLoading}>
              {isOverMaximumOrder
                ? t("order:never_mind")
                : isNewWeek
                  ? t("cancel_keep_order")
                  : t("order:no_dont_add_to_order")}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ExtraBakeTimeModal;
