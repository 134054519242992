import HeadAndMeta, { type LinkPreviewType } from "@/components/OldHeadAndMeta";
import Text from "@/components/v2/atoms/Text/Text";
import { SourceType } from "@/generated/requests/pos";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { type ReactNode, useEffect, useState } from "react";
import IconShoppingBagAltFilled from "../../atoms/Icons/ShoppingBagAltFilled";
import { SlideOut } from "../../atoms/SlideOut/SlideOut";
import { useLayoutContext } from "../../contexts/LayoutContext/LayoutContext";
import { useOrderContextNew } from "../../contexts/OrderContextNew/OrderContextNew";
import Button from "../../molecules/Button/Button";
import Cart from "../../organisms/Cart/Cart";
import Footer from "../../organisms/Footer/Footer";
import Header, { type HeaderVariant } from "../../organisms/Header/Header";
import SiteNav from "../../organisms/SiteNav/SiteNav";
import { useGetStoreById } from "../../operations/queries";

interface BasicLayoutProps {
  overrideOpenGraphTitle?: string;
  overrideOpenGraphDescription?: string;
  overrideOpenGraphImage?: string;
  overrideOpenGraphDisplayType?: LinkPreviewType;
  overrideOpenGraphVideo?: string;
  header?: boolean;
  headerVariant?: HeaderVariant;
  headerFade?: boolean;
  headerPadding?: boolean;
  cta?: boolean;
  mobileCta?: boolean;
  footer?: boolean;
  orderFromLocationPage?: boolean;
  children: ReactNode;
  hideFullWidth?: boolean;
  storeName?: string;
  isHomepage?: boolean;
}

/**
 * z-index usage
 *
 * A general note on z-index layers. Based on the provided classes from
 * tailwind.css, the following should be respected:
 *    • z-0, base layer
 *    • z-30, navbar layer
 *    • z-40, slide out menus
 *    • z-50, overlay content (rare, use sparingly)
 *
 * Most content should work with a `z-10` or `z-20` to layer below the navbar.
 * Use arbitrary values if required to layer between the higher layers (i.e.
 * `z-[31]`).
 */

/**
 * max-width
 *
 * Foreground content should never exceed `max-w-9xl`, but we can't enforce it
 * layout wide without affecting background content. It is the responsibility
 * of the coder to make sure components do not exceed this max-width when
 * building page content. FYI `max-w-9xl` is arbitrary – it's basically the
 * max width of my 16" MacBook pro and figured it was a decent max.
 */

export default function BasicLayout({
  overrideOpenGraphTitle = null,
  overrideOpenGraphDescription = null,
  overrideOpenGraphImage = null,
  overrideOpenGraphDisplayType = null,
  overrideOpenGraphVideo = null,
  header = true,
  headerVariant = "primary",
  headerFade = false,
  cta = true,
  mobileCta = false,
  footer = true,
  orderFromLocationPage = false,
  children,
  hideFullWidth = false,
  storeName = null,
  isHomepage = false,
}: BasicLayoutProps) {
  const { t } = useTranslation();
  const router = useRouter();
  const { order } = useOrderContextNew();
  const { showSiteNav, setShowSiteNav, showCart, setShowCart, showCta, hasOrder, isCarouselVisible } =
    useLayoutContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isViewBagLoading, setIsViewBagLoading] = useState<boolean>(false);
  const [isAnimate, setIsAnimate] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const totalItemsInCart = order?.items?.reduce((total, item) => total + item?.quantity, 0) || 0;
  const totalItemsInCartDisplay = Math.min(99, totalItemsInCart);
  const isOrderFlow = router?.isReady && router.pathname.match(/^\/order/);
  const isCheckoutPage = router?.isReady && router.pathname.match(/^\/order(.*)?\/checkout/);
  const storeSlug = router.query.slug as string;
  const orderType = router.query.type as string;

  // this is for when the page first loads on the home page, don't show the mobile order now button for a brief second before it fades away
  const isNoScroll = typeof window !== "undefined" && window.scrollY === 0;

  // server side rendering issue fix (hydration error)
  useEffect(() => {
    setIsMounted(true);
  }, []);

  // in case the user clicks on the bag button from the home page, and store slug is not in the url
  const { data: storeData } = useGetStoreById({
    storeId: order?.storeId,
  });

  useEffect(() => {
    showCart || showSiteNav
      ? document.body.classList.add("overflow-hidden")
      : document.body.classList.remove("overflow-hidden");
  }, [showCart, showSiteNav]);

  useEffect(() => {
    setIsAnimate(true);
    setTimeout(() => {
      setIsAnimate(false);
    }, 1000);
  }, [order?.items?.length]);

  useEffect(() => {
    if (isCheckoutPage) {
      setShowCart(false);
    }
  }, [router?.pathname]);

  const handleClickOrderNow = async () => {
    if (orderFromLocationPage) {
      setIsLoading(true);
      try {
        // get store by slug
        const { slug } = router?.query;
        router.push(`/order/${SourceType.CarryOut.toLowerCase()}/${slug}`);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    } else {
      router.push("/order");
    }
  };

  const handleCartDismiss = () => {
    if (!document.body.classList.contains("update-gift-wrap-modal-open")) {
      setShowCart(false);
    }
  };

  const handleViewBagClick = async () => {
    setIsViewBagLoading(true);
    const updatedOrderType = orderType || order?.source?.type?.toLowerCase();
    const updatedStoreSlug = storeSlug || storeData?.slug;
    await router.push(`/order/${updatedOrderType}/${updatedStoreSlug}/cart`);
    setIsViewBagLoading(false);
  };

  return (
    <>
      <HeadAndMeta
        overrideOpenGraphTitle={overrideOpenGraphTitle}
        overrideOpenGraphDescription={overrideOpenGraphDescription}
        overrideOpenGraphImage={overrideOpenGraphImage}
        overrideOpenGraphDisplayType={overrideOpenGraphDisplayType}
        overrideOpenGraphVideo={overrideOpenGraphVideo}
        storeName={storeName}
        isHomepage={isHomepage}
        isOrderFlow={!!isOrderFlow}
      />
      {(!!showSiteNav || !!showCart) && (
        <div
          role="invisible page cover when slideout is open to allow for clicking out of the slideout without interacting with the page"
          className="fixed inset-0 w-screen h-screen z-40"
        ></div>
      )}
      <SlideOut visible={!!showSiteNav} from="left" onDismiss={() => setShowSiteNav(false)} className="w-full max-w-96">
        <SiteNav onDismiss={() => setShowSiteNav(false)} />
      </SlideOut>
      {!!showCart && !!order?.items?.length && (
        <div className="fixed inset-0 w-screen h-screen bg-gray-500 opacity-50 z-30"></div>
      )}
      {isMounted && (
        <SlideOut
          visible={!!showCart && !!order?.items?.length}
          from="right"
          onDismiss={handleCartDismiss}
          className="w-full sm:max-w-[465px] hidden lg:block"
        >
          <Cart onDismiss={handleCartDismiss} />
        </SlideOut>
      )}
      {header && (
        <Header
          variant={headerVariant}
          fade={headerFade}
          callToAction={
            cta ? (
              <>
                {!!hasOrder && (
                  <div className="relative hidden sm:block">
                    <div
                      className={classNames(
                        "w-[16px] h-[16px] bg-[#EB0000] rounded-full absolute top-[8px] left-[20px] flex items-center justify-center text-xs text-white",
                        { "animate-bounce": isAnimate },
                      )}
                    >
                      {totalItemsInCartDisplay}
                    </div>
                    {/* We are doing two buttons here so that we get nicer behavior with swipe navigation on mobile devices, mobile will take you to a new screen and desktop will do modals still */}
                    <div className="lg:block hidden">
                      <Button
                        variant="primary"
                        contentLeft={<IconShoppingBagAltFilled className="text-white size-8" />}
                        className={classNames("invisible sm:visible mr-[22px] focus:ring-dark-blue", {
                          "animate-bounce": isAnimate,
                        })}
                        onClick={() => {
                          setShowCart(true);
                        }}
                      >
                        {t("order:view_bag")}
                      </Button>
                    </div>
                    <div className="lg:hidden">
                      <Button
                        variant="primary"
                        contentLeft={<IconShoppingBagAltFilled className="text-white size-8" />}
                        className={classNames("invisible sm:visible mr-[22px]", {
                          "animate-bounce": isAnimate,
                        })}
                        onClick={() => {
                          router.push(`/order/${orderType}/${storeSlug}/cart`);
                        }}
                      >
                        {t("order:view_bag")}
                      </Button>
                    </div>
                  </div>
                )}
                {!hasOrder && !isOrderFlow && (
                  <Button
                    variant="primary"
                    onClick={handleClickOrderNow}
                    className="invisible sm:visible mr-[22px]"
                    disabled={isLoading}
                  >
                    {t("order:order_now")}
                  </Button>
                )}
              </>
            ) : null
          }
          onMenuButtonClicked={() => setShowSiteNav(true)}
        />
      )}
      <div
        className={classNames(header && !headerFade && "pt-[60px] sm:pt-[80px]", "w-full flex flex-col items-center")}
      >
        <div className={classNames("sticky top-0 w-full", { "max-w-screen-2xl": !hideFullWidth })}>{children}</div>
        {hasOrder && cta && showCta && isMounted ? (
          <div
            className={classNames("fixed bottom-0 right-0 left-0 sm:hidden flex items-center px-[30px] pb-[30px] ", {
              "justify-center bg-gradient-to-b from-transparent via-white/80 to-white": isOrderFlow,
              "justify-end": !isOrderFlow,
              "transition-transform duration-500 ease-in-out": !isOrderFlow,
              "translate-y-full": isCarouselVisible && !isOrderFlow,
              "translate-y-0": !isCarouselVisible && !isOrderFlow,
            })}
          >
            <Button
              onClick={handleViewBagClick}
              className={classNames("flex justify-start z-30 lg:hidden", {
                "animate-bounce": isAnimate,
                "w-full": isOrderFlow,
                "pl-[5px] !pr-[15px]": !isOrderFlow,
              })}
              disabled={isViewBagLoading}
              contentRight={
                <div className={classNames("relative w-[28px] h-[28px] flex items-center justify-center")}>
                  <IconShoppingBagAltFilled className={classNames("text-white size-7")} />
                  <div
                    className={classNames(
                      "w-[14px] h-[14px] bg-[#FF4B4B] rounded-full absolute top-[0px] right-[0px] flex items-center justify-center text-xs text-white",
                    )}
                  >
                    <span className="text-[10px] leading-[0px] relative top-[-1px]">{totalItemsInCartDisplay}</span>
                  </div>
                </div>
              }
            >
              {!!isOrderFlow && (
                <Text variant="lineItem" className="w-max">
                  {t("order:view_bag")}
                </Text>
              )}
            </Button>
          </div>
        ) : (
          isMounted &&
          !hasOrder &&
          mobileCta &&
          !isOrderFlow &&
          (isHomepage ? !isNoScroll : true) && (
            <div
              className={classNames(
                "fixed bottom-0 right-0 left-0 sm:hidden flex items-center px-[30px] pb-[30px] justify-center bg-gradient-to-b from-transparent via-white/80 to-white",
                "transition-transform duration-500 ease-in-out",
                isCarouselVisible ? "translate-y-full" : "translate-y-0",
              )}
            >
              <Button variant="primary" onClick={handleClickOrderNow} className="w-full" disabled={isLoading}>
                {t("order:order_now")}
              </Button>
            </div>
          )
        )}
      </div>
      {footer && <Footer />}
    </>
  );
}
