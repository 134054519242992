import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import type { HTMLProps } from "react";
import IconTimesFilled from "../../atoms/Icons/TimesFilled";
import Text from "../../atoms/Text/Text";
import { useCustomerContext } from "../../contexts/CustomerContext/CustomerContext";
import { client } from "@/lib/apollo";
import { GetUserDocument } from "@/generated/requests/backend";
import useCustomerAccount from "../../hooks/useCustomerAccount";
import { useLayoutContext } from "../../contexts/LayoutContext/LayoutContext";

interface NavLink {
  title: string;
  href: string;
  external?: boolean;
  requiresAuthentication?: boolean;
}

const links: NavLink[] = [
  {
    title: "home",
    href: "/",
  },
  {
    title: "order",
    href: "/order",
  },
  {
    title: "locations",
    href: "/stores",
  },
  {
    title: "catering",
    href: "/catering",
  },
  {
    title: "gift_cards",
    href: "/gifting/giftcards",
    requiresAuthentication: true,
  },
  {
    title: "Merch",
    href: "https://merch.crumbl.com/",
    external: true,
  },
];

interface SiteNavProps extends HTMLProps<HTMLElement> {
  onDismiss?: Function;
}

export default function SiteNav({ onDismiss, className }: SiteNavProps) {
  const router = useRouter();
  const { t } = useTranslation();
  const { customer, signOut } = useCustomerContext();
  const { customerInfo } = useCustomerAccount();
  const { setShowAllCarouselItems } = useLayoutContext();
  const handleDismiss = () => {
    onDismiss?.();
  };

  const handleSignInClicked = () => {
    router.push({
      pathname: "/login",
      query: {
        next: router.asPath,
      },
    });
    handleDismiss();
  };

  const handleSignOutClicked = async () => {
    const customerId = customer?.userId;
    const userId = customerInfo?.userId;
    signOut();
    onDismiss?.();
    client.cache.evict({
      id: `UserRewardSummary:{"userId":"${customerId}"}`,
    });
    client.cache.evict({
      id: `User:{"userId":"${userId}"}`,
    });
    // user obj isn't updating in cache, so we need to manually set it to null
    client.writeQuery({
      query: GetUserDocument,
      data: { me: null },
    });
    client.cache.gc();
    // reset show all carousel items to false, just in case
    setShowAllCarouselItems(false);

    if (router.pathname.match(/^\/account/i)) {
      router.push("/");
    }
  };

  return (
    <div className={classNames("flex flex-col justify-between p-[30px] h-full", className)}>
      <div>
        <div className="flex flex-row-reverse justify-between items-center">
          <div>
            <button
              tabIndex={0}
              className={classNames(
                "flex items-center justify-center w-[50px] h-[50px]",
                "border border-black rounded-full",
                "transition-colors duration-500",
                "group hover:border-primary hover:bg-primary focus:border-primary focus:bg-primary",
              )}
              onClick={handleDismiss}
            >
              <IconTimesFilled
                height={30}
                width={30}
                className="group-hover:-rotate-180 group-focus:-rotate-180 transition-transform duration-500"
              />
            </button>
          </div>
          {customer?.userId ? (
            <div className="flex flex-col gap-y-[5px]">
              <Text variant="display4">
                {t("hi")}, {customer?.name?.split(" ")[0]}
              </Text>
              <Link href="/account" className="group" onClick={handleDismiss}>
                <Text
                  variant="body1"
                  className="group-hover:font-bold group-focus:font-bold group-hover:underline group-focus:underline"
                >
                  {t("view_my_account")}
                </Text>
              </Link>
            </div>
          ) : (
            <button
              className="transition-colors hover:text-primary-wcag focus:text-primary-wcag"
              onClick={handleSignInClicked}
            >
              <Text variant="display4">{t("sign_in")}</Text>
            </button>
          )}
        </div>
        <div className="my-[30px] border border-grey-10" />
        <div className="flex flex-col gap-y-[20px] sm:gap-y-[30px] lg:gap-y-[50px]">
          {links.map(({ title, href, requiresAuthentication, external }) => {
            const linkHref =
              requiresAuthentication && !customer?.userId
                ? {
                    pathname: `/login`,
                    query: {
                      next: href,
                    },
                  }
                : href;
            return (
              <Link
                key={href}
                href={linkHref}
                onClick={handleDismiss}
                className="transition-colors hover:text-primary-wcag focus:text-primary-wcag"
                target={external ? "_blank" : undefined}
              >
                <Text variant="display3">{t(title)}</Text>
              </Link>
            );
          })}
        </div>
      </div>
      <div className="flex basis-[60px] items-center justify-start">
        {!!customer?.userId && (
          <button onClick={handleSignOutClicked} className="group">
            <Text className="group-hover:font-bold group-focus:font-bold group-hover:underline group-focus:underline">
              {t("sign_out")}
            </Text>
          </button>
        )}
      </div>
    </div>
  );
}
