import { ProductModifierSpecialSubtype, SourceType } from "@/generated/requests/pos";
import { ProductModifierSpecialType } from "@/generated/requests/services";
import { ImagesV2 } from "@/public/images/all";
import { formatMoney } from "@/static/lib/util";
import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { useRouter } from "next/router";
import IconTrashRegular from "../../atoms/Icons/TrashRegular";
import Text from "../../atoms/Text/Text";
import type { ClientOrderItem } from "../../contexts/OrderContext/helpers";
import { useOrderContextNew } from "../../contexts/OrderContextNew/OrderContextNew";
import { hasAllSubtypes } from "../../contexts/OrderContextNew/helpers";
import { useGetSource, useGetStoreBySlug } from "../../operations/queries";
import QuantitySelectionButton from "../QuantitySelectionButton/QuantitySelectionButton";

type CartOrderItemProps = {
  item: any;
  updateQuantity?: (newQuantity: number) => void;
  editGiftWrapModifierForItem?: (productId: ClientOrderItem) => void;
  reward?: any;
  hideQuantitySelector?: boolean;
  isVouchered?: boolean;
  hideEditGiftWrap?: boolean;
};

export default function CartOrderItem({
  item,
  updateQuantity,
  editGiftWrapModifierForItem,
  reward,
  hideQuantitySelector = false,
  isVouchered,
  hideEditGiftWrap,
}: CartOrderItemProps) {
  const { locale } = useRouter();
  const router = useRouter();
  const { orderTimeSlot, order, orderLoading, isUpsertLoading } = useOrderContextNew();
  const { t } = useTranslation();

  const type = router.query.type as string;
  const orderType = type?.toUpperCase() as SourceType;
  const storeSlug = router.query.slug as string;

  const { data: storeData } = useGetStoreBySlug({ storeSlug: storeSlug });
  const { data: sourceProductsData } = useGetSource({
    storeId: storeData?.storeId || order?.storeId,
    type: orderType || order?.source?.type,
    timeSlot: orderTimeSlot,
  });

  const product = sourceProductsData?.public?.sourceForStore?.products?.find(
    (p) => p?.product?.productId === item?.product?.productId,
  );

  const editableLocation =
    typeof window !== "undefined" &&
    !window.location.href.includes("/checkout") &&
    product?.product?.modifiers?.flatMap((m) => m?.options).length > 1;

  const numberFormatter = new Intl.NumberFormat(locale, {});

  const itemModifiers = item?.modifiers?.map((itemModifier) => {
    const matchingProdModifier = product?.product?.modifiers?.find(
      (productModifier) => productModifier?.modifierId === itemModifier?.modifierId,
    );
    if (!matchingProdModifier) return itemModifier;

    const mergedOptions = itemModifier?.options.map((itemOption) => {
      const matchingOption2 = matchingProdModifier.options.find(
        (productModifierOption) => productModifierOption.optionId === itemOption.modifierOptionId,
      );
      return matchingOption2 ? { ...itemOption, ...matchingOption2 } : itemOption;
    });

    return {
      ...itemModifier,
      ...matchingProdModifier,
      options: mergedOptions,
    };
  });

  const itemFlavorModifier = itemModifiers?.find((m) => hasAllSubtypes(m, [ProductModifierSpecialSubtype.Cookie]));

  const singleBoxPackagingModifier = itemModifiers?.find(
    (m) => m?.specialType === ProductModifierSpecialType.CateringPackagingOptions,
  );

  const giftWrapModifierInItem = itemModifiers?.find((m) =>
    hasAllSubtypes(m, [ProductModifierSpecialSubtype.Giftwrapping]),
  );
  const giftBagModifierInItem = itemModifiers?.find((m) => hasAllSubtypes(m, [ProductModifierSpecialSubtype.Giftbag]));
  const selectedGiftWrapOptionId = item?.modifiers?.find(
    (itemModifier) => itemModifier?.modifierId === giftWrapModifierInItem?.modifierId,
  )?.options?.[0]?.modifierOptionId;
  const selectedGiftBagOptionId = item?.modifiers?.find(
    (itemModifier) => itemModifier?.modifierId === giftBagModifierInItem?.modifierId,
  )?.options?.[0]?.modifierOptionId;
  const selectedGiftWrapOption = giftWrapModifierInItem?.options?.find((o) => o?.optionId === selectedGiftWrapOptionId);
  const selectedGiftBagOption = giftBagModifierInItem?.options?.find((o) => o?.optionId === selectedGiftBagOptionId);
  const hasGiftOptions = !!selectedGiftWrapOption || !!selectedGiftBagOption;
  // #region Catering item price calculation logic
  const isCateringCookieModifier = (modifier) => {
    return hasAllSubtypes(modifier, [ProductModifierSpecialSubtype.Catering, ProductModifierSpecialSubtype.Cookie]);
  };
  const cateringCookieQuantity = itemModifiers
    ?.find((m) => isCateringCookieModifier(m))
    ?.options?.reduce((acc, o) => acc + (o?.quantity || 0), 0);
  const cateringItemsPrice =
    itemModifiers
      ?.filter((m) => isCateringCookieModifier(m))
      ?.reduce((acc, m) => {
        const modifierOptionPrice = m?.options?.reduce((acc, o) => {
          return acc + o?.price * o?.quantity;
        }, 0);
        return acc + modifierOptionPrice;
      }, 0) || 0;
  const cateringSingleBoxPrice = singleBoxPackagingModifier?.options?.[0]?.price || 0;
  const cateringSingleBoxesPrice = cateringSingleBoxPrice * cateringCookieQuantity;
  // #endregion

  // #region Price display logic
  const cateringItemPriceDisplay = formatMoney(cateringItemsPrice + cateringSingleBoxesPrice);
  const originalPriceDisplay =
    order?.source?.type === SourceType.Catering
      ? cateringItemPriceDisplay
      : product?.price
        ? formatMoney(product?.price)
        : "";
  const discountedPriceDisplay = formatMoney(product?.automaticDiscounts?.priceWithDiscounts);
  // #endregion

  // #region Line items texts
  const cateringSingleBoxPackagingTexts = singleBoxPackagingModifier?.options?.map((option) => {
    if (option?.price === 0) return "";
    return `${option?.quantity} x ${option?.name}${option?.price ? ` (+${formatMoney(option?.price)} ea.)` : ""}`;
  });
  const lineItemsTexts = itemFlavorModifier?.options
    ?.map((option) => {
      return `${option?.quantity} x ${option?.name}${option?.price ? ` (+${formatMoney(option?.price)} ea.)` : ""}`;
    })
    .concat(cateringSingleBoxPackagingTexts || []);
  // #endregion

  return (
    <div className="w-full py-[15px] border-b border-solid border-grey-10 flex flex-col animate-fadeInSlow">
      <div className="flex items-start w-full relative">
        {product?.product?.dynamicImage && (
          <div className="w-[90px] h-[90px] min-w-[90px] min-h-[90px] rounded-lg bg-[#FFB9CD] relative flex items-center justify-center">
            <Image
              src={product?.product?.dynamicImage}
              width={320}
              height={320}
              alt={t(product?.product?.name)}
              className="rounded-lg object-cover w-full h-full"
              tabIndex={0}
              aria-label={lineItemsTexts?.join(", ")}
            />
          </div>
        )}
        <div className="flex flex-col ml-[10px] flex-grow">
          <div className="flex items-start justify-between">
            <div>
              <Text variant="lineItem">{t(product?.product?.name)}</Text>
              <div className="mb-[10px] mt-1">
                <div className="flex gap-3">
                  <Text
                    variant="finePrint"
                    className={classNames({
                      "text-grey-60 line-through decoration-grey-60":
                        !!reward || !!product?.automaticDiscounts?.priceWithDiscounts || isVouchered,
                    })}
                  >
                    {originalPriceDisplay}
                  </Text>
                  {!!product?.automaticDiscounts?.priceWithDiscounts && !reward && !isVouchered && (
                    <Text
                      variant="finePrint"
                      className={classNames({
                        hidden: !!reward,
                      })}
                    >
                      {discountedPriceDisplay}
                    </Text>
                  )}
                  {isVouchered && <Text variant="finePrint">{t("order:free")}</Text>}
                </div>
                {reward && (
                  <Text variant="caption" as="span" className="bg-primary rounded px-2 py-1 my-1 !font-bold">
                    {t("order:x_crumbs", { x: numberFormatter.format(reward.pointCostAtTimeOfOrder) })}
                  </Text>
                )}
              </div>
            </div>
            <div>
              {!hideQuantitySelector ? (
                <QuantitySelectionButton
                  quantity={item?.quantity}
                  // We only allow incrementing quantity if this item is not a reward/vouchered item
                  onIncrement={reward || isVouchered ? null : () => updateQuantity(item?.quantity + 1)}
                  onDecrement={() => updateQuantity(item?.quantity - 1)}
                  iconForDecrement={
                    item?.quantity <= 1 && <IconTrashRegular width={20} height={20} fillOpacity={"0.7"} />
                  }
                  className="w-[110px] max-w-[110px] h-[40px] max-h-[40px]"
                  isLoading={isUpsertLoading || orderLoading}
                />
              ) : (
                <Text variant="lineItem" className="whitespace-nowrap">
                  {t("order:quantity_x", { x: item.quantity })}
                </Text>
              )}
            </div>
          </div>
          {lineItemsTexts?.map((lineItemText) => {
            return (
              <Text key={lineItemText} variant="finePrint" className="text-grey-40">
                {lineItemText}
              </Text>
            );
          })}
        </div>
      </div>

      {hasGiftOptions && (
        <div className="w-full flex justify-between border border-solid border-grey-10 rounded-lg gap-2.5 items-center mt-3 py-2">
          <div className="flex items-center">
            <Image src={ImagesV2.giftWrapBow} alt="Gift wrap bow" className="size-12" />
            <div className="flex flex-col">
              <Text variant="smallHeader">{t("order:gift_wrap")}</Text>
              {selectedGiftWrapOption && (
                <div className="flex gap-1 items-center">
                  <Text variant="finePrint">
                    {t("order:bow_and_sticker")}: "{selectedGiftWrapOption?.name}"
                  </Text>
                  <Text variant="finePrint">+({formatMoney(selectedGiftWrapOption?.price)})</Text>
                </div>
              )}
              {selectedGiftBagOption && (
                <div className="flex gap-1 items-center">
                  <Text variant="finePrint">{selectedGiftBagOption?.name}</Text>
                  <Text variant="finePrint">+({formatMoney(selectedGiftBagOption?.price)})</Text>
                </div>
              )}
            </div>
          </div>
          {!hideEditGiftWrap && (
            <div className="px-4 pb-4">
              <button onClick={() => editGiftWrapModifierForItem(item)}>
                <Text variant="finePrint" className="underline text-grey-60">
                  {t("order:edit")}
                </Text>
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
